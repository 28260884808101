<!-- 语法 -->
<template>
    <div class="">
        <div @click="mf2()">开始执行数据库</div>
        <yitable :data="[data0,data1,data2,data3]"></yitable>
    </div>
</template>

<script>
    import axios from 'axios'
    import yitable from "@/components/yilou/table.vue";
    export default {
        name: '',
        components: {
            yitable,
        },
        data() { // 数据源
            return {
                data0: [],
                data1: [],
                data2: [],
                data3: [],
                i: 2023001,
            }
        },
        mounted() {
            this.mfd()
            this.mfz()
            // this.mf2()
            this.mf4()
        },
        computed: {

        },
        methods: { // 函数体部分，js的主要逻辑控制   
            mfd() {//走势图
                document.documentElement.scrollTop = 0;
                axios({
                    method: "post",
                    url: "https://sd-ycl.com/lot/fd",
                    // params: { record: 50 }
                }).then(
                    (res) => {
                        var data0 = []
                        var x = res.data
                        x.forEach((item) => {
                            var obj = {
                                'number': item.match(/Code=(\S*), /)[1],
                                'yilou': parseInt(item.match(/id1=(\S*)}/)[1]) - parseInt(item.match(/id2=(\S*) /)[1])
                            }
                            data0.push(obj)
                        })
                        this.data0 = data0

                    },
                )
            },
            mfz() {//走势图
                axios({
                    method: "post",
                    url: "https://sd-ycl.com/lot/fz",
                    //data: {  record: 50}
                    //params: { record: 50 }
                }).then(
                    (res) => {
                        var data0 = []
                        var x = res.data
                        x.forEach((item) => {
                            var obj = {
                                'number': item.match(/Code=(\S*), /)[1],
                                'yilou': parseInt(item.match(/id1=(\S*)}/)[1]) - parseInt(item.match(/id2=(\S*) /)[1])
                            }
                            data0.push(obj)
                        })
                        this.data1 = data0
                    },
                )
            },

            mf2() {//走势图

                axios({
                    method: "get",
                    //url: "https://sd-ycl.com/lot/f2",
                    url: "http://localhost:8088/lottery/mytask/prize",
                    //url: "http://localhost:8088/lottery/demo/find",
                    //data: obj
                    //params:{tab:"fc3d",pageNum:2,pageSize:10}
                }).then(
                    (res) => {

                        var arr0 = []
                        var x = res.data
                        console.log(x)
                        var data = x.klbfind2
                        // console.log(data)
                        for (var i = 0; i < data.length; i++) {
                            var arr = []
                            var openCode = 0
                            var max = 0
                            var count = 0
                            var rownum = 100000
                            for (var a = 0; a < data[i].length; a++) {
                                arr.push(data[i][a].match(/rownum=(\S*), /)[1]),
                                    openCode = data[i][a].match(/openCode=(\S*), /)[1]
                                count = data[i][a].match(/count=(\S*)}/)[1]
                            }
                            arr.push(count)
                            arr.sort(function (a, b) {
                                return a - b;
                            });
                            for (var b = 0; b < arr.length; b++) {
                                if (rownum > parseInt(arr[b])) {
                                    rownum = parseInt(arr[b]);
                                }
                            }
                            if (arr.length != 1) {
                                for (var c = 0; c < arr.length - 1; c++) {
                                    if (max < (parseInt(arr[c + 1]) - parseInt(arr[c]))) {
                                        max = parseInt(arr[c + 1]) - parseInt(arr[c])
                                    }
                                }
                            } else {
                                max = parseInt(arr[0])
                            }

                            var obj = {
                                openCode: openCode,
                                number: rownum == count ? 0 : arr.length - 1,
                                rownum: rownum,
                                max: max,
                                count: parseInt(count),
                            }
                            arr0.push(obj)
                        }

                        console.log(arr0)


                        // var data0 = []
                        // var x = res.data
                        // x.forEach((item) => {
                        //     var obj = {
                        //         'number': item.match(/Code=(\S*), /)[1],
                        //         'yilou': parseInt(item.match(/id1=(\S*)}/)[1]) - parseInt(item.match(/id2=(\S*) /)[1])
                        //     }
                        //     data0.push(obj)
                        // })
                        // this.data2 = data0
                    },
                )
            },
            mf4() {//走势图
                axios({
                    method: "post",
                    url: "https://sd-ycl.com/lot/f4",
                    //data: {  record: 50}
                    //params: { record: 50 }
                }).then(
                    (res) => {
                        var data0 = []
                        var x = res.data
                        x.forEach((item) => {
                            var obj = {
                                'number': item.match(/Code=(\S*), /)[1],
                                'yilou': parseInt(item.match(/id1=(\S*)}/)[1]) - parseInt(item.match(/id2=(\S*) /)[1])
                            }
                            data0.push(obj)
                        })
                        this.data3 = data0
                    },
                )
            },
        },
        props: {
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
</style>