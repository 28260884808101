<template>
  <div style="padding:5px;" id="app">
    <div class="title">
      <div :class="danfu == i ? 'fu1' : 'ssqfua'" v-for="(item,i) in list" :key="i" @click="fu(i)" >
       {{item}}
      </div>
      <div class="clear"></div>
    </div>

    <div class="panel panel-primary">
      <!-- <div class="panel-heading"> {{data[1]}}</div> -->
      <table class="table table-bordered table-striped text-center">
        <thead>
          <tr>
            <th>序号</th>
            <th>号码</th>
            <th>当前遗漏</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,i) in danfu==0?data[0]:(danfu==1?data[1]:(danfu==2?data[2]:(danfu==3?data[3]:'')))" :key="i">
            <td>{{i+1}}</td>
            <td>{{item.number}}</td>
            <td>{{item.yilou}}期</td>
          </tr>
        
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'hello',
    data() {
      return {
        list:['单选遗漏查询','组选遗漏查询','二码遗漏查询','四码遗漏查询'],
        danfu: [0],
        // title: '福彩3D遗漏查询',
        // users: [
        //   { 'number': '345', 'yilou': '25' },
        //   { 'number': '264', 'yilou': '23' },
        //   { 'number': '876', 'yilou': '22' }
        // ]
      }
    },
    mounted() {
     // alert(this.data[0])
    },
    computed: {
    
    },
    methods: {
    fu(i){
      this.danfu=i
    }
    },
    props: ['data']
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /* *{
        border:1px solid red;
    } */
  .panel-heading {
    font-size: 1.4em;
  }

  td,
  tr,
  th {
    text-align: center;
    width: 10%;
    border: 1px solid rgb(233, 229, 229);
  }

  table {
    font-size: 1.2em;
    margin: 0 auto;
    border: 1px solid rgb(233, 229, 229);
  }
  .title {
    width: 60%;
    margin: 0 auto;
  }
  .ssqfua {
    width: 23%;
    float: left;
    margin-left: 1%;
    font-size: 16px;
    border: 1px solid rgb(170, 169, 169);
  }
  .fu1 {
    width: 23%;
    float: left;
    margin-left: 1%;
    font-size: 16px;
    border: 1px solid rgb(170, 169, 169);
    background-color: blue;
    color: aliceblue;
  }
</style>